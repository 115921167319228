import React, {
  useState,
  useRef,
  Fragment,
  useCallback,
  useEffect,
} from 'react';
import useParentWidth from '../../../hooks/useParentWidth';
import { useHistory } from 'react-router-dom';
import { StorageIcon, TickSuccessIcon } from '../icon';
import {
  PaginationNext,
  PaginationPrev,
} from '../../../assets/icons/nav-icons';
import { Modal, Button } from 'react-bootstrap';
import {
  TableLink,
  TableDateCell,
} from '../../../components/order/common/tableCells';
import Container from 'react-bootstrap/Container';
import Table, {
  DefaultColumnFilter,
  StatusColumnFilter,
  CustomerFilter,
} from '../../../components/order/common/table';
import { getOrders, inactiveOrder, orderDelete } from '../../../services/order';
import { useDispatch } from 'react-redux';
import { actions } from '@naadi/framework';
import { toastError } from '../../../helpers/packing/packingHelper';
import { FaExclamationCircle } from 'react-icons/fa';
import { isEqual } from 'lodash';
const width = { minWidth: '171px' };
const HeaderODelete = ({ selectedOrders }) => {
  const history = useHistory();
  const container = useRef();

  useParentWidth(container);

  const goBack = useCallback(() => {
    history.push('/app/orders/list');
  }, [history]);

  return (
    <Fragment>
      <div className='d-flex mt-3'>
        <div
          style={{ paddingLeft: '20px' }}
          className={'cursor-pointer'}
          onClick={goBack}
        >
          <svg
            width='32'
            height='28'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M15 19L16.41 17.59L11.83 13L10.8294 12.0357L11.83 11L16.42 6.41L15 5L8 12L15 19Z'
              fill='black'
            />
          </svg>
        </div>
        <div className='page-title d-flex align-items-center'>
          Delete Orders ({selectedOrders.length} selected)
        </div>
      </div>
    </Fragment>
  );
};
export const StorageBar = ({ usedStorages, totalStorage = 5 }) => {
  const usedStorage = usedStorages / 1024;
  const usedPercentage = (usedStorage / totalStorage) * 100;
  const remaining = `${(totalStorage - usedStorage).toFixed(2)} GB`;
  const isExceeded = usedStorage > totalStorage;
  const progressBarColor = isExceeded ? 'bg-danger' : 'bg-primary';
  const iconColor = isExceeded ? ' #C00000' : '#027AFF';

  return (
    <div
      className='mt-2 position-relative'
      style={{
        width: '353px',
        left: '39px',
        height: '56px',
        paddingLeft: '10px',
        paddingRight: '10px',
        border: '1px solid #F5F5F5',
        borderRadius: '8px',
        backgroundColor: '#F5F5F5',
      }}
    >
      <div
        className='d-flex align-items-center justify-content-between rounded'
        style={{ paddingTop: '2px' }}
      >
        <div className='d-flex align-items-center'>
          <StorageIcon iconColor={iconColor} />
          <span
            className='position-absolute'
            style={{ color: 'black', left: '60px', fontSize: '14px' }}
          >
            Storage
          </span>
        </div>
        <span
          style={{
            fontSize: '14px',
            color: isExceeded ? '#C00000' : '#027AFF',
          }}
        >
          {isExceeded ? `0 GB (${remaining})` : `${remaining}`} left
        </span>
      </div>
      <div
        className='progress position-relative'
        style={{
          height: '8px',
          borderRadius: '5px',
          maxWidth: '280px',
          left: '50px',
        }}
      >
        <div
          className={`progress-bar ${progressBarColor}`}
          role='progressbar'
          style={{ width: `${usedPercentage}%` }}
          aria-valuenow={usedPercentage}
          aria-valuemin='0'
          aria-valuemax='100'
        ></div>
      </div>
    </div>
  );
};

export const PaginationComponent = ({ totalCount, pageNo, onPageChange }) => {
  const pageSize = 50;
  const pageStart = pageNo * pageSize;
  const pageEnd = Math.min((pageNo + 1) * pageSize, totalCount);
  const isPrevDisabled = pageNo === 0;
  const isNextDisabled = pageEnd >= totalCount;
  const onPrevPage = () => {
    if (!isPrevDisabled) {
      const newPage = pageNo - 1;
      onPageChange(newPage);
    }
  };
  const onNextPage = () => {
    if (!isNextDisabled) {
      const newPage = pageNo + 1;
      onPageChange(newPage);
    }
  };
  return (
    <div className='d-flex justify-content-end mt-3'>
      <span style={{ fontSize: '12px' }}>Showing</span>
      <span
        className='text-primary ps-1'
        style={{ fontSize: '12px' }}
      >{`${pageStart} to ${pageEnd}`}</span>
      <span style={{ padding: '0 10px' }} onClick={onPrevPage}>
        <PaginationPrev disabled={isPrevDisabled} />
      </span>
      <span style={{ padding: '0 16px' }} onClick={onNextPage}>
        <PaginationNext disabled={isNextDisabled} />
      </span>
    </div>
  );
};
export const TrashBanner = ({ show, orderCount, onViewTrash, action }) => {
  if (!show) return null;
  const message =
    action === 'restore'
      ? `${orderCount.length} Orders successfully restored`
      : action === 'deleted'
      ? `${orderCount.length} Orders Deleted successfully`
      : `${orderCount.length} Orders moved to trash`;

  return (
    <div
      className='d-flex align-items-center justify-content-between'
      style={{
        position: 'fixed',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#2C2F33',
        color: '#FFFFFF', // white text
        padding: '2px 8px',
        borderRadius: '20px',
        zIndex: 1050,
        boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
        minWidth: '150px',
      }}
    >
      <TickSuccessIcon />
      <span style={{ fontSize: '12px', paddingLeft: '2px' }}>{message}</span>
      <div
        style={{
          color: '#027AFF',
          textDecoration: 'underline',
          marginLeft: '10px',
          fontWeight: 'bold',
          fontSize: '12px',
          cursor: 'pointer',
        }}
      ></div>
    </div>
  );
};

export const RestoreDeletedOrderModal = ({
  showModal,
  setShowModal,
  onRestore,
  action,
}) => {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sortBy, setSortBy] = useState([{ column: 'created_on', asc: false }]);
  const sortByRef = useRef(sortBy);
  const [pageNo, setPageNo] = useState(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [restoreDelete, setRestoreDelete] = useState(false);
  const pageSize = 50;
  const dispatch = useDispatch();
  const handleSelectOrder = useCallback(
    order => {
      const isSelected = selectedOrders.includes(order);
      if (isSelected) {
        setSelectedOrders(prevSelectedOrders =>
          prevSelectedOrders.filter(selectedOrder => selectedOrder !== order),
        );
      } else {
        setSelectedOrders(prevSelectedOrders => [...prevSelectedOrders, order]);
      }
    },
    [selectedOrders],
  );
  const handleSelectAll = useCallback(() => {
    if (selectAll) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(allOrders);
    }
    setSelectAll(prevSelectAll => !prevSelectAll);
  }, [selectAll, allOrders]);
  const handlePageChange = useCallback(newPageNo => {
    setPageNo(newPageNo);
  }, []);
  useEffect(() => {
    const fetchOrders = async () => {
      const filter = {
        term: '',
        code: [],
        code_term: [],
        ext_code_term: [],
        entity_id: [],
        fetch_branch_detail: true,
        fetch_count: true,
        fetch_entities: true,
        notuuid: [],
        parent_order_id_term: [],
        ref_code: [],
        ref_code_term: [],
        secondary_customer_term: [],
        status: [],
        delivery_date: '',
        name: [],
      };

      const page_params = {
        page_no: pageNo,
        page_size: pageSize,
      };

      try {
        const allOrders = await getOrders(filter, page_params, null, false);
        const payload = allOrders.payload;
        const total = allOrders.total;
        setAllOrders(payload);
        if (pageNo === 0) {
          setTotalCount(total);
        } else {
        }
      } catch (error) {
        toastError.error('Failed to fetch orders:', error);
      }
    };

    fetchOrders();
  }, [pageNo]);

  const restoreOrder = useCallback(async () => {
    try {
      dispatch(actions.busy.add('ORDER_DELETE'));
      onRestore(selectedOrders);
      for (const order of selectedOrders) {
        await inactiveOrder(order.uuid, true);
      }
      setSelectedOrders([]);
      action('restore');
    } catch (error) {
      toastError('order delete failed');
    } finally {
      dispatch(actions.busy.remove('ORDER_DELETE'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedOrders]);
  const deleteOrder = useCallback(async () => {
    try {
      dispatch(actions.busy.add('ORDER_DELETE'));
      onRestore(selectedOrders);
      for (const order of selectedOrders) {
        await orderDelete(order.uuid);
      }
      setSelectedOrders([]);
      action('deleted');
    } catch (error) {
      toastError('order delete failed');
    } finally {
      dispatch(actions.busy.remove('ORDER_DELETE'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedOrders]);
  const columns = [
    {
      Header: () => (
        <div className='d-flex align-items-center'>
          <input
            type='checkbox'
            onChange={() => handleSelectAll()}
            checked={selectAll}
            style={{ marginRight: '5px' }}
          />
          <span className='ml-2'>Order</span>
        </div>
      ),
      accessor: 'ref_code',
      Cell: ({ row }) => {
        // eslint-disable-line
        const { values, original } = row; // eslint-disable-line
        return (
          <div className='d-flex align-items-center'>
            <div className='d-flex'>
              <input
                type='checkbox'
                onChange={() => handleSelectOrder(row.original)}
                checked={selectedOrders.includes(row.original)}
                style={{ marginRight: '5px' }}
              />
            </div>
            <TableLink
              text={values.ref_code}
              url={`/app/orders/details/${original.uuid}`}
              is_bold
            />
          </div>
        ); // eslint-disable-line
      },
      Filter: DefaultColumnFilter,
      width: 100,
      filter_key: 'term',
    },
    {
      Header: 'Parent Order',
      accessor: 'parent_order',
      Filter: DefaultColumnFilter,
      Cell: ({ row }) => {
        // eslint-disable-line
        const { values } = row; // eslint-disable-line
        if (values.parent_order.uuid) {
          // eslint-disable-line
          return (
            <TableLink
              text={values.parent_order.ref_code}
              url={`/app/orders/details/${values.parent_order.uuid}`}
            />
          ); // eslint-disable-line
        }
        return <div></div>;
      },
      filter_key: 'parent_order_id_term',
    },
    {
      Header: 'Customer Reference',
      accessor: 'ext_code',
      Filter: DefaultColumnFilter,
      minWidth: '155px',
      filter_key: 'ext_code_term',
    },
    {
      Header: 'Customer',
      accessor: 'customer_name',
      Filter: CustomerFilter,
      Cell: ({ row }) => {
        // eslint-disable-line
        const { values, original } = row; // eslint-disable-line
        return (
          <TableLink
            text={values.customer_name}
            url={`/app/customers/${original.entity.uuid}`}
          />
        ); // eslint-disable-line
      },
      filter_key: 'customer_name',
    },
    {
      Header: 'Secondary Customer',
      accessor: 'secondary_customer',
      Cell: ({ row }) => {
        const { original } = row;
        if (!original.secondary_customer) {
          return <div></div>;
        }
        return <div>{original.secondary_customer}</div>;
      },
      filter_key: 'secondary_customer_term',
      Filter: DefaultColumnFilter,
    },
    {
      Header: 'Branch',
      accessor: 'branch_id',
      filter_key: 'branch_id',
      Cell: ({ row }) => {
        const { original } = row;
        if (!original.branch_id || !original.branch) {
          return <div></div>;
        }
        return <div>{original.branch.name}</div>;
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      filter_key: 'status',
      Filter: StatusColumnFilter,
    },
    {
      Header: 'Order Date',
      accessor: 'created_on',
      disableFilters: false,
      Cell: ({ row }) => {
        // eslint-disable-line
        const { values } = row; // eslint-disable-line
        return <TableDateCell value={values.created_on} />;
      },
    },
    {
      Header: 'Planned Shipment Date',
      accessor: 'delivery_date',
      disableFilters: false,
      Cell: ({ row }) => {
        // eslint-disable-line
        const { values } = row; // eslint-disable-line
        return values.delivery_date ? (
          <TableDateCell value={values.delivery_date} />
        ) : (
          ''
        );
      },
    },
  ];
  return (
    <div>
      <Modal show={showModal} size='xl' onHide={() => setShowModal(false)}>
        <Modal.Header closeButton style={{ borderBottom: 0 }}>
          <Modal.Title>
            Recently Deleted
            <p style={{ fontWeight: '200', fontSize: '12px' }}>
              Orders get automatically removed in 30 days of deletion
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: '450px', padding: '0px' }}>
          <Container fluid className=' h-100'>
            <div>
              <PaginationComponent
                totalCount={totalCount}
                pageNo={pageNo}
                onPageChange={handlePageChange}
              />
            </div>
            <div className='mt-2'>
              <Table
                columns={columns}
                data={allOrders}
                // has_filters
                columnSort={sortBy}
                onSortChange={_sortBy => {
                  const updatedSortBy = _sortBy.map(val => {
                    return {
                      column: val.id,
                      asc: val.desc !== true,
                    };
                  });
                  if (!isEqual(sortByRef.current, updatedSortBy)) {
                    sortByRef.current = updatedSortBy;
                    setSortBy(sortByRef.current);
                  }
                }}
              />
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-end'>
          <Button
            variant='outline-primary'
            style={width}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant='outline-danger'
            style={width}
            onClick={() => setShowConfirmDelete(true)}
          >
            Delete Selected
          </Button>
          <Button
            variant='primary'
            style={width}
            onClick={() => setRestoreDelete(true)}
          >
            Restore
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showConfirmDelete}
        onHide={() => setShowConfirmDelete(false)}
        centered
      >
        <Modal.Header closeButton className='border-0 P-2'></Modal.Header>
        <Modal.Body
          className='text-center'
          style={{ paddingTop: '4px', paddingBottom: '50px' }}
        >
          <FaExclamationCircle size={48} className='text-danger mb-3' />
          <h5 className='fw-bold'>
            Delete {selectedOrders.length} orders Permanently?
          </h5>
          <p className='mb-0'>
            Permanently deleted orders cannot be restored in the future.
          </p>
        </Modal.Body>
        <Modal.Footer className='border-0 P-2 d-flex justify-content-between'>
          <Button
            variant='outline-primary'
            onClick={() => setShowConfirmDelete(false)}
            style={{ width: '46%' }}
          >
            Cancel
          </Button>
          <Button
            variant='danger'
            onClick={() => {
              setShowModal(false);
              deleteOrder();
            }}
            style={{ width: '46%' }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={restoreDelete}
        onHide={() => setRestoreDelete(false)}
        centered
      >
        <Modal.Header closeButton className='border-0 P-2'></Modal.Header>
        <Modal.Body
          className='text-center'
          style={{ paddingTop: '4px', paddingBottom: '50px' }}
        >
          <FaExclamationCircle size={48} className='text-primary mb-3' />
          <h5 className='fw-bold'>Restore Recently Deleted</h5>
          <p className='mb-0'>
            Deleted orders can be restored within next 30 days post which it
            will be deleted forever.
          </p>
        </Modal.Body>
        <Modal.Footer className='border-0 P-2 d-flex justify-content-between'>
          <Button
            variant='outline-primary'
            onClick={() => setRestoreDelete(false)}
            style={{ width: '46%' }}
          >
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              setShowModal(false);
              restoreOrder();
            }}
            style={{ width: '46%' }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HeaderODelete;
